import {
  orgFolderSchema,
  orgFolderSchemaUI,
} from "./gcpResourceSchemas/orgFolderSchema";
import {
  folderSchema,
  folderSchemaUI,
} from "./gcpResourceSchemas/folderSchema";
import {
  organizationSchema,
  organizationUISchema,
} from "./gcpResourceSchemas/organizationSchema";
import {
  projectSchema,
  projectSchemaUI,
} from "./gcpResourceSchemas/projectSchema";
import {
  hostProjectSchema,
  hostProjectSchemaUI,
} from "./gcpResourceSchemas/hostProjectSchema";
import {
  serviceProjectSchema,
  serviceProjectSchemaUI,
} from "./gcpResourceSchemas/serviceProjectSchema";
import {
  folderIAMSchema,
  folderIAMSchemaUI,
} from "./gcpResourceSchemas/folderIAMSchema";
import {
  projectIAMSchema,
  projectIAMSchemaUI,
} from "./gcpResourceSchemas/projectIAMSchema";
import {
  orgIAMSchema,
  orgIAMSchemaUI,
} from "./gcpResourceSchemas/orgIAMSchema";
import { vpcSchema, vpcSchemaUI } from "./gcpResourceSchemas/vpcSchema";
import {
  firewallsSchema,
  firewallsSchemaUI,
} from "./gcpResourceSchemas/firewallsSchema";
import {
  routerSchema,
  routerSchemaUI,
} from "./gcpResourceSchemas/routerSchema";
import { gcsSchema, gcsSchemaUI } from "./gcpResourceSchemas/gcsSchema";
import {
  serviceAccountsSchema,
  serviceAccountsSchemaUI,
} from "./gcpResourceSchemas/serviceAccountsSchema";
import {
  instanceTemplateSchema,
  instanceTemplateSchemaUi,
} from "./gcpResourceSchemas/instanceTemplateSchema";
import {
  instanceSchema,
  instanceSchemaUI,
} from "./gcpResourceSchemas/instanceSchema";
import { migSchema, migSchemaUI } from "./gcpResourceSchemas/migSchema";
import {
  httpsLBSchema,
  httpsLBSchemaUI,
} from "./gcpResourceSchemas/httpsLBSchema";
import {
  publicIPSchema,
  publicIPSchemaUI,
} from "./gcpResourceSchemas/publicIPSchema";
import { tcpudpLBSchema } from "./gcpResourceSchemas/tcpudpLBSchema";
import { internalTCPUDPLBSchema } from "./gcpResourceSchemas/internalTCPUDPLBSchema";
import { gkeSchema } from "./gcpResourceSchemas/gkeSchema";
import { gkeSchemaUI } from "./gcpResourceSchemas/gkeSchemaUI";
import { bqSchema } from "./gcpResourceSchemas/bqSchema";
import { projectSourceSchema } from "./gcpResourceSchemas/projectSourceSchema";
import {
  folderSourceSchema,
  folderSourceSchemaUI,
} from "./gcpResourceSchemas/folderSourceSchema";
import {
  awsVpcSchema,
  awsVpcSchemaUI,
} from "./gcpResourceSchemas/awsVpcSchema";
import {
  awsAFTSchema,
  awsAFTUISchema,
} from "./gcpResourceSchemas/awsAFTSchema";
import {
  awsAFTaccountRequestSchema,
  awsAFTaccountRequestSchemaUI,
} from "./gcpResourceSchemas/awsAFTaccountRequestSchema";
import {
  awsAFTaccountCustomizationsSchema,
  awsAFTaccountCustomizationsUISchema,
} from "./gcpResourceSchemas/awsAFTaccountCustomizationsSchema";
import {
  awsIAMaccountSchema,
  awsIAMaccountUISchema,
} from "./gcpResourceSchemas/awsIAMaccountSchema";
import {
  awsIAMassumablePredefinedRolesSchema,
  awsIAMassumablePredefinedRolesUISchema,
} from "./gcpResourceSchemas/awsIAMassumablePredefinedRolesSchema";
import {
  awsIAMpoliciesSchema,
  awsIAMpoliciesSchemaUISchema,
} from "./gcpResourceSchemas/awsIAMpoliciesSchema";
import {
  awsIAMgroupsWithPoliciesSchema,
  awsIAMgroupsWithPoliciesUISchema,
} from "./gcpResourceSchemas/awsIAMgroupsWithPoliciesSchema";
import {
  awsIAMusersSchema,
  awsIAMusersUISchema,
} from "./gcpResourceSchemas/awsIAMusersSchema";
import {
  awsIAMassumableRoleWithOidcSchema,
  awsIAMassumableRoleWithOidcUISchema,
} from "./gcpResourceSchemas/awsIAMassumableRoleWithOidcSchema";
import {
  awsIAMassumablePredefinedRolesWithSamlSchema,
  awsIAMassumablePredefinedRolesWithSamlUISchema,
} from "./gcpResourceSchemas/awsIAMassumablePredefinedRolesWithSamlSchema";
import {
  awsIAMassumableRolesSchema,
  awsIAMassumableRolesUISchema,
} from "./gcpResourceSchemas/awsIAMassumableRolesSchema";
import {
  awsIAMassumableRolesWithSamlSchema,
  awsIAMassumableRolesWithSamlUISchema,
} from "./gcpResourceSchemas/awsIAMassumableRolesWithSamlSchema";
import {
  awsSecurityGroupSchema,
  awsSecurityGroupUISchema,
} from "./gcpResourceSchemas/awsSecurityGroupSchema";
import { awsS3Schema, awsS3SUIchema } from "./gcpResourceSchemas/awsS3Schema";
import {
  awsEKSschema,
  awsEKSUIschema,
} from "./gcpResourceSchemas/awsEKSschema";
import {
  awsEKSmanagedNodeGroupUIschema,
  awsEKSmanagedNodeGroupSchema,
} from "./gcpResourceSchemas/awsEKSmanagedNodeGroupSchema";
import {
  awsEKSfargateProfileSchema,
  awsEKSfargateProfileUISchema,
} from "./gcpResourceSchemas/awsEKSfargateProfileSchema";
import {
  gcpSaferMySQLSchema,
  gcpSaferMySQLUISchema,
} from "./gcpResourceSchemas/gcpSaferMySQLSchema";
import {
  gcpCloudSQLprivateAccessSchema,
  gcpCloudSQLprivateAccessUISchema,
} from "./gcpResourceSchemas/gcpCloudSQLprivateAccessSchema";
import {
  gcpMemorystoreSchema,
  gcpMemorystoreUISchema,
} from "./gcpResourceSchemas/gcpMemorystoreSchema";
import {
  gcpMemcacheSchema,
  gcpMemcacheUISchema,
} from "./gcpResourceSchemas/gcpMemcacheSchema";
import {
  gcpPubSubSchema,
  gcpPubSubUISchema,
} from "./gcpResourceSchemas/gcpPubSubSchema";
import {
  metastoreSchema as databricksMetastoreSchema,
  metastoreUISchema as databricksMetastoreUISchema,
} from "./databricsSchemas/metastoreSchema";
import {
  workspaceSchema as databricksWorkspaceSchema,
  workspaceUISchema as databricksWorkspaceUISchema,
} from "./databricsSchemas/workspaceSchema";
import {
  databricksAccountUsersSchema,
  databricksAccountUsersUISchema,
} from "./databricsSchemas/databricksAccountUsersSchema";
import {
  databricksAccountAdminGroupSchema,
  databricksAccountAdminGroupUISchema,
} from "./databricsSchemas/databricksAccountAdminGroupSchema";
import {
  databricksAccountGroupSchema,
  databricksAccountGroupUISchema,
} from "./databricsSchemas/databricksAccountGroupSchema";
import {
  databricksMetaStoreGrantsSchema,
  databricksMetaStoreGrantsUISchema,
} from "./databricsSchemas/metastoreGrantsSchema";
import {
  databricksCatalogSchema,
  databricksCatalogUISchema,
} from "./databricsSchemas/catalogSchema";
import {
  databricksSchemaSchema,
  databricksSchemaUISchema,
} from "./databricsSchemas/catalogSchemaSchema";
import {
  databricksUCexternalStorageSchema,
  databricksUCexternalStorageUISchema,
} from "./databricsSchemas/databricksUCexternalStorageSchema";
import {
  databricksClusterConfigSchema,
  databricksClusterConfigUISchema,
} from "./databricsSchemas/clusterConfigSchema";
import {
  databricksWorkspacePermissionsSchema,
  databricksWorkspacePermissionsUISchema,
} from "./databricsSchemas/databricksWorkspacePermissionsSchema";
import {
  databricksWorkspaceAdminSchema,
  databricksWorkspaceAdminUISchema,
} from "./databricsSchemas/workspaceAdminSchema";
import {
  databricksNotebookSchema,
  databricksNotebookUISchema,
} from "./databricsSchemas/notebookSchema";

export const gcpResourceConfigs = {
  orgFolder: {
    allowedChildren: [
      "GCP Project",
      "GCP Folder IAM",
      "GCP Folder",
      "GCP Host Project",
    ],
    icon: "../GCP/folder.svg",
    displayName: "GCP Org Folder",
    displayField: "name", // Which field from node.properties should be displayed
    priority: 10,
    schema: orgFolderSchema,
    uiSchema: orgFolderSchemaUI,
    url: "https://github.com/terraform-google-modules/terraform-google-folders",
    versions: ["v4.0.0"],
  },
  folder: {
    allowedChildren: [
      "GCP Project",
      "GCP Host Project",
      "GCP Folder IAM",
      "GCP Folder",
    ],
    icon: "../GCP/folder.svg",
    displayName: "GCP Folder",
    displayField: "name",
    priority: 11,
    schema: folderSchema,
    uiSchema: folderSchemaUI,
    url: "https://github.com/terraform-google-modules/terraform-google-folders",
    versions: ["v4.0.0"],
  },
  organization: {
    allowedChildren: [
      "GCP Org Folder",
      "GCP Project",
      "GCP Host Project",
      "GCP Org IAM",
      "GCP Project Data Source",
      "GCP Folder Data Source",
      "AWS Organizational Unit",
      "AWS Account",
      //"AWS Account Factory Terraform",
      "AWS Control Tower Account",
      "AWS AFT Account",
      "AWS Databricks Account",
    ],
    icon: "../circle-15.svg",
    displayName: "Global Variables",
    priority: 1,
    schema: organizationSchema,
    uiSchema: organizationUISchema,
    isDataSource: true,
  },
  project: {
    allowedChildren: [
      "Service Accounts",
      "Project IAM",
      "VPC",
      "GCS Bucket",
      "Instance Template",
      "HTTP(S) LB",
      //"Public IPs",
      //"External TCP/UDP LB",
      //"Internal TCP/UDP LB",
      "GKE",
      //"BQ",
      "GCP Cloud SQL (MySQL)",
      "GCP Memorystore",
      "GCP Memcache",
      "GCP PubSub",
    ],
    icon: "../GCP/project.svg",
    displayName: "GCP Project",
    displayField: "name",
    priority: 20,
    url: "https://github.com/terraform-google-modules/terraform-google-project-factory",
    versions: ["v14.2.0"],
    schema: projectSchema,
    uiSchema: projectSchemaUI,
  },
  hostProject: {
    allowedChildren: [
      "Service Accounts",
      "Project IAM",
      "VPC",
      "GCP Service Project",
      "GCS Bucket",
      "Instance Template",
      "HTTP(S) LB",
      //"Public IPs",
      //"External TCP/UDP LB",
      //"Internal TCP/UDP LB",
      "GKE",
      //"BQ",
      "GCP Cloud SQL (MySQL)",
      "GCP Memorystore",
      "GCP Memcache",
      "GCP PubSub",
    ],
    icon: "../GCP/project.svg",
    displayName: "GCP Host Project",
    displayField: "name",
    priority: 21,
    schema: hostProjectSchema,
    uiSchema: hostProjectSchemaUI,
    url: "https://github.com/terraform-google-modules/terraform-google-project-factory",
    versions: ["v14.2.0"],
  },
  serviceProject: {
    allowedChildren: [
      "Service Accounts",
      "Project IAM",
      "VPC",
      "GCS Bucket",
      "Instance Template",
      "HTTP(S) LB",
      "Public IPs",
      //"External TCP/UDP LB",
      //"Internal TCP/UDP LB",
      "GKE",
      //"BQ",
      "GCP Cloud SQL (MySQL)",
      "GCP Memorystore",
      "GCP Memcache",
    ],
    icon: "../GCP/project.svg",
    displayName: "GCP Service Project",
    displayField: "name",
    priority: 40,
    schema: serviceProjectSchema,
    uiSchema: serviceProjectSchemaUI,
    url: "https://github.com/terraform-google-modules/terraform-google-project-factory",
    versions: ["v14.2.0"],
  },
  folderIam: {
    allowedChildren: [],
    icon: "../GCP/iam.svg",
    displayName: "GCP Folder IAM",
    displayField: "name",
    priority: 5,
    schema: folderIAMSchema,
    uiSchema: folderIAMSchemaUI,
    url: "https://github.com/terraform-google-modules/terraform-google-iam/tree/master/modules/folders_iam",
    versions: ["v7.6.0"],
  },
  projectIam: {
    allowedChildren: [],
    icon: "../GCP/iam.svg",
    displayName: "Project IAM",
    displayField: "name",
    priority: 5,
    schema: projectIAMSchema,
    uiSchema: projectIAMSchemaUI,
    url: "https://github.com/terraform-google-modules/terraform-google-iam/tree/master/modules/projects_iam",
    versions: ["v7.6.0"],
  },
  orgIam: {
    allowedChildren: [],
    icon: "../GCP/iam.svg",
    displayName: "GCP Org IAM",
    displayField: "name",
    priority: 5,
    schema: orgIAMSchema,
    uiSchema: orgIAMSchemaUI,
    url: "https://github.com/terraform-google-modules/terraform-google-iam/tree/master/modules/organizations_iam",
    versions: ["v7.6.0"],
  },
  vpc: {
    allowedChildren: [
      "Firewall Rules",
      "Router",
      "GCP Cloud SQL Private Access",
    ],
    icon: "../GCP/vpc.svg",
    displayName: "VPC",
    displayField: "network_name",
    priority: 30,
    schema: vpcSchema,
    uiSchema: vpcSchemaUI,
    url: "https://github.com/terraform-google-modules/terraform-google-network",
    versions: ["v7.1.0"],
  },
  firewalls: {
    allowedChildren: [],
    icon: "../GCP/firewalls.svg",
    displayName: "Firewall Rules",
    displayField: "name",
    priority: 5,
    schema: firewallsSchema,
    uiSchema: firewallsSchemaUI,
    url: "https://github.com/terraform-google-modules/terraform-google-network/tree/master/modules/firewall-rules",
    versions: ["v7.1.0"],
  },
  router: {
    allowedChildren: [],
    icon: "../GCP/router.svg",
    displayName: "Router",
    displayField: "name",
    priority: 100,
    schema: routerSchema,
    uiSchema: routerSchemaUI,
    url: "https://github.com/terraform-google-modules/terraform-google-cloud-router",
    versions: ["v5.0.1"],
  },
  gcs: {
    allowedChildren: [],
    icon: "../../GCP/gcs.svg",
    displayName: "GCS Bucket",
    displayField: "name",
    priority: 100,
    schema: gcsSchema,
    uiSchema: gcsSchemaUI,
    url: "https://github.com/terraform-google-modules/terraform-google-cloud-storage",
    versions: ["v4.0.0"],
  },
  serviceAccounts: {
    allowedChildren: [],
    icon: "../GCP/service-account.svg",
    displayName: "Service Accounts",
    displayField: "name",
    priority: 4,
    schema: serviceAccountsSchema,
    uiSchema: serviceAccountsSchemaUI,
    url: "https://github.com/terraform-google-modules/terraform-google-service-accounts",
    versions: ["v4.2.1"],
  },
  instanceTemplate: {
    allowedChildren: ["Instance From Template", "Managed Instance Group"],
    icon: "../../GCP/compute-engine.svg",
    displayName: "Instance Template",
    displayField: "name_prefix",
    schema: instanceTemplateSchema,
    priority: 100,
    uiSchema: instanceTemplateSchemaUi,
    url: "https://github.com/terraform-google-modules/terraform-google-vm/tree/master/modules/instance_template",
    versions: ["v8.0.1"],
  },
  instance: {
    allowedChildren: [],
    icon: "../GCP/compute-engine.svg",
    displayName: "Instance From Template",
    priority: 110,
    displayField: "hostname",
    schema: instanceSchema,
    uiSchema: instanceSchemaUI,
    url: "https://github.com/terraform-google-modules/terraform-google-vm/tree/master/modules/compute_instance",
    versions: ["v8.0.1"],
  },
  mig: {
    allowedChildren: [],
    icon: "../GCP/compute-engine.svg",
    displayName: "Managed Instance Group",
    displayField: "mig_name",
    priority: 110,
    schema: migSchema,
    uiSchema: migSchemaUI,
    url: "https://github.com/terraform-google-modules/terraform-google-vm/tree/master/modules/mig",
    versions: ["v8.0.1"],
  },
  httpsLB: {
    allowedChildren: [],
    displayName: "HTTP(S) LB",
    displayField: "name",
    icon: "../GCP/lb.svg",
    schema: httpsLBSchema,
    uiSchema: httpsLBSchemaUI,
    priority: 100,
  },
  publicIPs: {
    allowedChildren: [],
    icon: "../GCP/public-ip.svg",
    displayName: "Public IPs",
    displayField: "name",
    schema: publicIPSchema,
    uiSchema: publicIPSchemaUI,
    priority: 90,
    url: "https://github.com/terraform-google-modules/terraform-google-lb-http",
    versions: ["v9.1.0"],
  },
  tcpudpLB: {
    allowedChildren: [],
    icon: "../GCP/lb.svg",
    displayName: "External TCP/UDP LB",
    displayField: "name",
    schema: tcpudpLBSchema,
    priority: 100,
  },
  internalTCPUDPLB: {
    allowedChildren: [],
    displayName: "Internal TCP/UDP LB",
    displayField: "name",
    icon: "../GCP/lb.svg",
    priority: 100,
    schema: internalTCPUDPLBSchema,
  },
  gke: {
    allowedChildren: [],
    displayName: "GKE",
    displayField: "name",
    icon: "../GCP/gke.svg",
    priority: 100,
    schema: gkeSchema,
    uiSchema: gkeSchemaUI,
    url: "https://github.com/terraform-google-modules/terraform-google-kubernetes-engine/tree/master/modules/safer-cluster",
    versions: ["v27.0.0"],
  },
  bq: {
    allowedChildren: [],
    displayName: "BQ",
    displayField: "dataset_name",
    icon: "../GCP/bq.svg",
    schema: bqSchema,
    priority: 100,
    uiSchema: {
      tables: {
        items: {
          schema: {
            "ui:widget": "textarea",
          },
        },
      },
      views: {
        items: {
          query: {
            "ui:widget": "textarea",
          },
        },
      },
    },
  },
  gcpSaferMySQL: {
    allowedChildren: [],
    displayName: "GCP Cloud SQL (MySQL)",
    displayField: "name",
    icon: "../GCP/cloud_sql.svg",
    schema: gcpSaferMySQLSchema,
    priority: 99,
    uiSchema: gcpSaferMySQLUISchema,
    url: "https://github.com/terraform-google-modules/terraform-google-sql-db/tree/master/modules/safer_mysql",
    versions: ["v17.0.0"],
  },
  gcpCloudSQLprivateAccess: {
    allowedChildren: [],
    displayName: "GCP Cloud SQL Private Access",
    displayField: "name",
    icon: "../GCP/vpc.svg",
    schema: gcpCloudSQLprivateAccessSchema,
    priority: 100,
    uiSchema: gcpCloudSQLprivateAccessUISchema,
    url: "https://github.com/terraform-google-modules/terraform-google-sql-db/tree/master/modules/private_service_access",
    versions: ["v17.0.0"],
  },
  gcpMemorystore: {
    allowedChildren: [],
    displayName: "GCP Memorystore",
    displayField: "name",
    icon: "../GCP/memorystore.svg",
    schema: gcpMemorystoreSchema,
    priority: 101,
    uiSchema: gcpMemorystoreUISchema,
    url: "https://github.com/terraform-google-modules/terraform-google-memorystore",
    versions: ["v7.1.2"],
  },
  gcpMemcache: {
    allowedChildren: [],
    displayName: "GCP Memcache",
    displayField: "name",
    icon: "../GCP/memorystore.svg",
    schema: gcpMemcacheSchema,
    priority: 102,
    uiSchema: gcpMemcacheUISchema,
    url: "https://github.com/terraform-google-modules/terraform-google-memorystore/tree/master/modules/memcache",
    versions: ["v7.1.2"],
  },
  gcpPubSub: {
    allowedChildren: [],
    displayName: "GCP PubSub",
    displayField: "topic",
    icon: "../GCP/pubsub.svg",
    schema: gcpPubSubSchema,
    priority: 99,
    uiSchema: gcpPubSubUISchema,
    url: "https://github.com/terraform-google-modules/terraform-google-pubsub",
    versions: ["v5.0.0"],
  },
  project_source: {
    allowedChildren: [
      "GCS Bucket",
      "GKE",
      "VPC",
      "Project IAM",
      "Service Accounts",
      "Instance Template",
      "HTTP(S) LB",
      "GCP Cloud SQL (MySQL)",
      "GCP Memorystore",
      "GCP Memcache",
      "GCP PubSub",
    ],
    displayName: "GCP Project Data Source",
    displayField: "project_id",
    priority: 21,
    schema: projectSourceSchema,
    icon: "../GCP/project.svg",
    isDataSource: true,
  },
  folder_source: {
    allowedChildren: ["GCP Folder IAM", "GCP Project", "GCP Host Project"],
    displayName: "GCP Folder Data Source",
    displayField: "id",
    priority: 9,
    schema: folderSourceSchema,
    uiSchema: folderSourceSchemaUI,
    icon: "../GCP/folder.svg",
    isDataSource: true,
  },
  awsOU: {
    allowedChildren: ["AWS Organizational Unit", "AWS Account"],
    icon: "../AWS/organizationalUnit.svg",
    priority: 100,
    displayField: "name",
    displayName: "AWS Organizational Unit",
    isDataSource: true,
    schema: {
      type: "object",
      title: "AWS Organizational Unit data settings",
      properties: {
        name: {
          type: ["string", "null"],
          title: "Name",
        },
      },
    },
    uiSchema: {
      "ui:description": "AWS Organizational Unit data settings",
      name: {
        "ui:description":
          "Name of the AWS Organizational Unit. (Options). This value is not used by child modules. It is just a placeholder.",
        "ui:emptyValue": null,
        "ui:textarea": "true",
      },
    },
  },
  awsAccount: {
    allowedChildren: ["AWS Region"],
    icon: "../AWS/account.svg",
    priority: 101,
    displayField: "name",
    displayName: "AWS Account",
    isDataSource: true,
    schema: {
      type: "object",
      title: "AWS Account data",
      properties: {
        name: {
          type: "string",
          title: "Name",
        },
        aws_account_id: {
          type: ["string", "null"],
          title: "AWS Account ID",
        },
        aws_partition: {
          type: "string",
          title: "AWS Partition",
          default: "aws",
        },
        assume_role_arn: {
          type: ["string", "null"],
          title: "Assume Role ARN",
        },
        aft_oidc_role: {
          type: ["string", "null"],
          title: "AFT OIDC Role",
        },
      },
    },
    uiSchema: {
      "ui:description": "AWS Account data",
      name: {
        "ui:description": "Name of the AWS Account",
      },
      aws_account_id: {
        "ui:description": "The AWS Account ID.",
        "ui:emptyValue": null,
      },
      aws_partition: {
        "ui:description": "AWS Partition. In most cases this is 'aws'.",
      },
      assume_role_arn: {
        "ui:description":
          "The ARN of the role to be assumed with web identity in the Account (Optional). Ignored if bellow AFT OIDC Role is set.",
        "ui:widget": "textarea",
        "ui:emptyValue": null,
        "ui:options": {
          rows: 2,
        },
      },
      aft_oidc_role: {
        "ui:description":
          "The ARN of the role created by AFT. This role is in the form arn:aws:iam::<AFT Managment Account Id>:role/xoogify-oidc-hyperautomation. In order to use this role you must set AWS Account ID above. If set then the final role assumed by role chaning will be arn:<aws_partition>:iam::<AWS account id>:role/AWSAFTExecution. This takes precedence over above defined Assume Role ARN. (Optional)",
        "ui:widget": "textarea",
        "ui:options": {
          rows: 2,
        },
        "ui:emptyValue": null,
      },
    },
  },
  awsRegion: {
    allowedChildren: [
      "AWS VPC",
      "AWS IAM Account",
      "AWS IAM Assumable Predefined Roles",
      "AWS IAM Policies",
      "AWS IAM Groups With Policies",
      "AWS IAM Users",
      "AWS IAM Assumable Roles",
      "AWS IAM Assumable Role With OIDC",
      "AWS IAM Assumable Predefined Roles with SAML",
      "AWS IAM Assumable Roles with SAML",
      "AWS S3 Bucket",
      "AWS Databricks Metastore",
    ],
    icon: "../AWS/account.svg",
    priority: 132,
    displayField: "aws_region",
    displayName: "AWS Region",
    isDataSource: true,
    schema: {
      type: "object",
      title: "AWS Region",
      properties: {
        aws_region: {
          type: ["string", "null"],
          title: "Name",
        },
      },
    },
    uiSchema: {
      "ui:description": "AWS Region name. This data is used by child modules.",
      aws_region: {
        "ui:description": "The name of the AWS Region. (Required)",
        "ui:emptyValue": null,
        "ui:textarea": "true",
      },
    },
  },
  awsCTaccount: {
    allowedChildren: ["AWS Account Factory Terraform (AFT)"],
    icon: "../AWS/account.svg",
    displayField: "aws_account_id",
    displayName: "AWS Control Tower Account",
    priority: 97,
    isDataSource: true,
    schema: {
      type: "object",
      title: "AWS Control Tower Account",
      properties: {
        aws_account_id: {
          type: ["string", "null"],
          title: "AWS Account ID",
        },
        assume_role_arn: {
          type: ["string", "null"],
          title: "Assume Role ARN",
        },
      },
    },
    uiSchema: {
      "ui:description": "AWS Control Tower Account data",
      aws_account_id: {
        "ui:description":
          "The AWS Account ID of the Control Tower Account (Optional). This value can be used instead of CT Management Account ID in the child module.",
        "ui:emptyValue": null,
      },
      assume_role_arn: {
        "ui:description":
          "The ARN of the role to be assumed with web identity in the Control Tower Account (Optional)",
        "ui:widget": "textarea",
        "ui:emptyValue": null,
      },
    },
  },
  awsAFT: {
    allowedChildren: [],
    icon: "../AWS/controlTower.svg",
    priority: 105,
    displayField: "name",
    displayName: "AWS Account Factory Terraform (AFT)",
    schema: awsAFTSchema,
    uiSchema: awsAFTUISchema,
    url: "https://github.com/aws-ia/terraform-aws-control_tower_account_factory",
    versions: ["v1.10.4"],
  },
  awsVpc: {
    allowedChildren: ["AWS Security Group", "AWS EKS"],
    icon: "../AWS/vpc.svg",
    displayName: "AWS VPC",
    displayField: "name",
    priority: 130,
    schema: awsVpcSchema,
    uiSchema: awsVpcSchemaUI,
    url: "https://github.com/terraform-aws-modules/terraform-aws-vpc",
    versions: ["v5.1.1"],
  },
  awsAFTaccount: {
    allowedChildren: ["AFT Account Requests", "AFT Account Customizations"],
    icon: "../AWS/account.svg",
    displayField: "aws_account_id",
    displayName: "AWS AFT Account",
    priority: 98,
    isDataSource: true,
    schema: {
      type: "object",
      title: "AWS AFT Account",
      properties: {
        aws_account_id: {
          type: ["string", "null"],
          title: "AWS Account ID",
        },
        assume_role_arn: {
          type: ["string", "null"],
          title: "Assume Role ARN",
        },
      },
    },
    uiSchema: {
      "ui:description": "AWS AFT Account data",
      aws_account_id: {
        "ui:description":
          "The AWS Account ID of the Account Factory Terraform (AFT) (Optional). This account must be created via Control Tower first. This value can be used instead of AFT Management Account ID in the child module.",
        "ui:emptyValue": null,
      },
      assume_role_arn: {
        "ui:description":
          "The ARN of the role to be assumed with web identity in the AFT Account (Optional)",
        "ui:widget": "textarea",
      },
    },
  },
  awsAFTaccountRequest: {
    allowedChildren: [],
    displayName: "AFT Account Requests",
    icon: "../AWS/account.svg",
    priority: 115,
    displayField: "name",
    schema: awsAFTaccountRequestSchema,
    uiSchema: awsAFTaccountRequestSchemaUI,
    url: "https://github.com/marko7460/aft-account-request",
    versions: ["v1.0.0"],
  },
  awsAFTaccountCustomizations: {
    allowedChildren: [],
    displayName: "AFT Account Customizations",
    icon: "../AWS/account.svg",
    priority: 120,
    displayField: "name",
    schema: awsAFTaccountCustomizationsSchema,
    uiSchema: awsAFTaccountCustomizationsUISchema,
    url: "https://github.com/marko7460/aft-account-customizations",
    versions: ["v0.1.0"],
  },
  awsIAMaccount: {
    allowedChildren: [],
    displayName: "AWS IAM Account",
    icon: "../AWS/iam.svg",
    priority: 121,
    displayField: "account_alias",
    schema: awsIAMaccountSchema,
    uiSchema: awsIAMaccountUISchema,
    url: "https://github.com/terraform-aws-modules/terraform-aws-iam/tree/master/modules/iam-account",
    versions: ["v5.30.0"],
  },
  awsIAMassumableRoles: {
    allowedChildren: [],
    displayName: "AWS IAM Assumable Roles",
    icon: "../AWS/iam.svg",
    priority: 122,
    displayField: "name",
    schema: awsIAMassumableRolesSchema,
    uiSchema: awsIAMassumableRolesUISchema,
    url: "https://github.com/terraform-aws-modules/terraform-aws-iam/tree/master/wrappers/iam-assumable-role",
    versions: ["v5.30.0"],
  },
  awsIAMassumablePredefinedRoles: {
    allowedChildren: [],
    displayName: "AWS IAM Assumable Predefined Roles",
    icon: "../AWS/iam.svg",
    priority: 122,
    displayField: "name",
    schema: awsIAMassumablePredefinedRolesSchema,
    uiSchema: awsIAMassumablePredefinedRolesUISchema,
    url: "https://github.com/terraform-aws-modules/terraform-aws-iam/tree/master/modules/iam-assumable-roles",
    versions: ["v5.30.0"],
  },
  awsIAMpolicies: {
    allowedChildren: [],
    displayName: "AWS IAM Policies",
    icon: "../AWS/iam.svg",
    priority: 123,
    displayField: "name",
    schema: awsIAMpoliciesSchema,
    uiSchema: awsIAMpoliciesSchemaUISchema,
    url: "https://github.com/terraform-aws-modules/terraform-aws-iam/tree/master/wrappers/iam-policy",
    versions: ["v5.30.0"],
  },
  awsIAMgroupsWithPolicies: {
    allowedChildren: [],
    displayName: "AWS IAM Groups With Policies",
    icon: "../AWS/iam.svg",
    priority: 125,
    displayField: "name",
    schema: awsIAMgroupsWithPoliciesSchema,
    uiSchema: awsIAMgroupsWithPoliciesUISchema,
    url: "https://github.com/terraform-aws-modules/terraform-aws-iam/tree/master/wrappers/iam-group-with-policies",
    versions: ["v5.30.0"],
  },
  awsIAMusers: {
    allowedChildren: [],
    displayName: "AWS IAM Users",
    icon: "../AWS/iam.svg",
    priority: 124,
    displayField: "name",
    schema: awsIAMusersSchema,
    uiSchema: awsIAMusersUISchema,
    url: "https://github.com/terraform-aws-modules/terraform-aws-iam/tree/master/wrappers/iam-user",
    versions: ["v5.30.0"],
  },
  awsIAMassumableRoleWithOidc: {
    allowedChildren: [],
    displayName: "AWS IAM Assumable Role With OIDC",
    icon: "../AWS/iam.svg",
    priority: 125,
    displayField: "role_name",
    schema: awsIAMassumableRoleWithOidcSchema,
    uiSchema: awsIAMassumableRoleWithOidcUISchema,
    url: "https://github.com/terraform-aws-modules/terraform-aws-iam/tree/master/modules/iam-assumable-role-with-oidc",
    versions: ["v5.30.0"],
  },
  awsIAMassumablePredefinedRolesWithSaml: {
    allowedChildren: [],
    displayName: "AWS IAM Assumable Predefined Roles with SAML",
    icon: "../AWS/iam.svg",
    priority: 126,
    displayField: "name",
    schema: awsIAMassumablePredefinedRolesWithSamlSchema,
    uiSchema: awsIAMassumablePredefinedRolesWithSamlUISchema,
    url: "https://github.com/terraform-aws-modules/terraform-aws-iam/tree/master/modules/iam-assumable-roles-with-saml",
    versions: ["v5.30.0"],
  },
  awsIAMassumableRolesWithSaml: {
    allowedChildren: [],
    displayName: "AWS IAM Assumable Roles with SAML",
    icon: "../AWS/iam.svg",
    priority: 127,
    displayField: "name",
    schema: awsIAMassumableRolesWithSamlSchema,
    uiSchema: awsIAMassumableRolesWithSamlUISchema,
    url: "https://github.com/terraform-aws-modules/terraform-aws-iam/tree/master/wrappers/iam-assumable-role-with-saml",
    versions: ["v5.30.0"],
  },
  awsSecurityGroup: {
    allowedChildren: [],
    displayName: "AWS Security Group",
    icon: "../AWS/firewall.svg",
    priority: 120,
    displayField: "name",
    schema: awsSecurityGroupSchema,
    uiSchema: awsSecurityGroupUISchema,
    url: "https://github.com/terraform-aws-modules/terraform-aws-security-group",
    versions: ["v5.1.0"],
  },
  awsS3: {
    allowedChildren: [],
    displayName: "AWS S3 Bucket",
    icon: "../AWS/s3standard.svg",
    priority: 120,
    displayField: "bucket",
    schema: awsS3Schema,
    uiSchema: awsS3SUIchema,
    url: "https://github.com/terraform-aws-modules/terraform-aws-s3-bucket",
    versions: ["v3.15.1"],
  },
  awsEKS: {
    allowedChildren: ["AWS EKS Managed Node Group", "AWS EKS Farget Profile"],
    displayName: "AWS EKS",
    icon: "../AWS/eks.svg",
    priority: 120,
    displayField: "cluster_name",
    schema: awsEKSschema,
    uiSchema: awsEKSUIschema,
    url: "https://github.com/terraform-aws-modules/terraform-aws-eks",
    versions: ["v19.16.0"],
  },
  awsEKSmanagedNodeGroup: {
    allowedChildren: [],
    displayName: "AWS EKS Managed Node Group",
    icon: "../AWS/eks.svg",
    priority: 120,
    displayField: "name",
    schema: awsEKSmanagedNodeGroupSchema,
    uiSchema: awsEKSmanagedNodeGroupUIschema,
    url: "https://github.com/terraform-aws-modules/terraform-aws-eks/tree/master/modules/eks-managed-node-group",
    versions: ["v19.16.0"],
  },
  awsEKSfargateProfile: {
    allowedChildren: [],
    displayName: "AWS EKS Farget Profile",
    icon: "../AWS/iam.svg",
    priority: 121,
    displayField: "name",
    schema: awsEKSfargateProfileSchema,
    uiSchema: awsEKSfargateProfileUISchema,
    url: "https://github.com/terraform-aws-modules/terraform-aws-eks/tree/master/modules/fargate-profile",
    versions: ["v19.16.0"],
  },
  awsDatabricksAccount: {
    allowedChildren: [
      "AWS Account",
      "Databricks Account Users",
      "Databricks Account Admin Group",
      "Databricks Account Group",
    ],
    displayName: "AWS Databricks Account",
    icon: "../DATABRICKS/account.svg",
    priority: 105,
    isDataSource: true,
    displayField: "databricks_account_id",
    schema: {
      type: "object",
      title: "AWS Databricks Account",
      properties: {
        databricks_account_id: {
          type: "string",
          title: "Databricks Account ID",
          description:
            "Value of the ID of your Databricks account. You can find this value in the corner of your Databricks account console.",
        },
        databricks_client_id: {
          type: "string",
          title: "Client ID",
          description:
            "Value of the client ID, also known as the application ID, of the service principal.",
        },
      },
    },
    uiSchema: {
      "ui:description": "AWS Databricks Account data",
    },
  },
  awsDatabricksMetastore: {
    allowedChildren: ["AWS Databricks Workspace"],
    displayName: "AWS Databricks Metastore",
    icon: "../DATABRICKS/data.svg",
    priority: 120,
    displayField: "metastore_name",
    schema: databricksMetastoreSchema,
    uiSchema: databricksMetastoreUISchema,
    url: "https://github.com/marko7460/terraform-databricks-aws-unity-catalog/tree/main/modules/metastore",
    versions: ["v0.1.0"],
  },
  awsDatabricksWorkspace: {
    allowedChildren: [
      "Databricks Workspace Admin",
      "Databricks Workspace Permissions",
      "Databricks Metastore Grants",
      "Databricks Catalog",
      "Databricks AWS External Storage",
      "Databricks Cluster Config",
      "Databricks Notebook",
    ],
    displayName: "AWS Databricks Workspace",
    icon: "../DATABRICKS/workspace.svg",
    priority: 121,
    displayField: "workspace_name",
    schema: databricksWorkspaceSchema,
    uiSchema: databricksWorkspaceUISchema,
    url: "https://github.com/marko7460/terraform-databricks-aws-workspace",
    versions: ["v0.1.0"],
  },
  databricksAccountUsers: {
    allowedChildren: [],
    displayName: "Databricks Account Users",
    icon: "../DATABRICKS/users.svg",
    priority: 10,
    displayField: "name",
    schema: databricksAccountUsersSchema,
    uiSchema: databricksAccountUsersUISchema,
    url: "https://github.com/marko7460/terraform-databricks-security/tree/main/modules/users",
    versions: ["v0.1.0"],
  },
  databricksAccountAdminGroup: {
    allowedChildren: [],
    displayName: "Databricks Account Admin Group",
    icon: "../DATABRICKS/users.svg",
    priority: 11,
    displayField: "admin_group_name",
    schema: databricksAccountAdminGroupSchema,
    uiSchema: databricksAccountAdminGroupUISchema,
    url: "https://github.com/marko7460/terraform-databricks-security/tree/main/modules/admin-group",
    versions: ["v0.1.0"],
  },
  databricksAccountGroup: {
    allowedChildren: [],
    displayName: "Databricks Account Group",
    icon: "../DATABRICKS/users.svg",
    priority: 12,
    displayField: "display_name",
    schema: databricksAccountGroupSchema,
    uiSchema: databricksAccountGroupUISchema,
    url: "https://github.com/marko7460/terraform-databricks-security/tree/main/modules/group",
    versions: ["v0.1.0"],
  },
  datatbricksWorkspacePermissions: {
    allowedChildren: [],
    displayName: "Databricks Workspace Permissions",
    icon: "../DATABRICKS/users.svg",
    priority: 9,
    displayField: "name",
    schema: databricksWorkspacePermissionsSchema,
    uiSchema: databricksWorkspacePermissionsUISchema,
    url: "https://github.com/marko7460/terraform-databricks-security/tree/main/modules/workspace_permissions",
    versions: ["v0.1.0"],
  },
  databricksWorkspaceAdmin: {
    allowedChildren: [],
    displayName: "Databricks Workspace Admin",
    icon: "../DATABRICKS/dashboard.svg",
    priority: 8,
    displayField: "name",
    schema: databricksWorkspaceAdminSchema,
    uiSchema: databricksWorkspaceAdminUISchema,
    url: "https://github.com/marko7460/terraform-databricks-aws-workspace/tree/main/modules/workspace_admin",
    versions: ["v0.1.0"],
  },
  databricksMetastoreGrants: {
    allowedChildren: [],
    displayName: "Databricks Metastore Grants",
    icon: "../DATABRICKS/users.svg",
    priority: 10,
    displayField: "name",
    schema: databricksMetaStoreGrantsSchema,
    uiSchema: databricksMetaStoreGrantsUISchema,
    url: "https://github.com/marko7460/terraform-databricks-aws-unity-catalog/tree/main/modules/metastore-grants",
    versions: ["v0.1.0"],
  },
  databricksCatalog: {
    allowedChildren: ["Databricks Schema"],
    displayName: "Databricks Catalog",
    icon: "../DATABRICKS/catalog.svg",
    priority: 11,
    displayField: "name",
    schema: databricksCatalogSchema,
    uiSchema: databricksCatalogUISchema,
    url: "https://github.com/marko7460/terraform-databricks-aws-unity-catalog/tree/main/modules/catalog",
    versions: ["v0.1.0"],
  },
  databricksSchema: {
    allowedChildren: [],
    displayName: "Databricks Schema",
    icon: "../DATABRICKS/schema.svg",
    priority: 10,
    displayField: "name",
    schema: databricksSchemaSchema,
    uiSchema: databricksSchemaUISchema,
    url: "https://github.com/marko7460/terraform-databricks-aws-unity-catalog/tree/main/modules/schema",
    versions: ["v0.1.0"],
  },
  databricksUCexternalStorage: {
    allowedChildren: [],
    displayName: "Databricks AWS External Storage",
    icon: "../DATABRICKS/data.svg",
    priority: 13,
    displayField: "bucket",
    schema: databricksUCexternalStorageSchema,
    uiSchema: databricksUCexternalStorageUISchema,
    url: "https://github.com/marko7460/terraform-databricks-aws-unity-catalog/tree/main/modules/external-storage",
    versions: ["v0.1.0"],
  },
  databricksClusterConfig: {
    allowedChildren: [],
    displayName: "Databricks Cluster Config",
    icon: "../DATABRICKS/compute.svg",
    priority: 14,
    displayField: "cluster_name",
    schema: databricksClusterConfigSchema,
    uiSchema: databricksClusterConfigUISchema,
    url: "https://github.com/marko7460/terraform-databricks-aws-workspace/tree/main/modules/cluster_config",
    versions: ["v0.1.0"],
  },
  databricksNotebook: {
    allowedChildren: [],
    displayName: "Databricks Notebook",
    icon: "../DATABRICKS/notebook.svg",
    priority: 15,
    displayField: "path",
    schema: databricksNotebookSchema,
    uiSchema: databricksNotebookUISchema,
    url: "https://github.com/marko7460/terraform-databricks-aws-workspace/tree/main/modules/notebook",
    versions: ["v0.1.0"],
  },
};

export function getType(displayName) {
  for (const key in gcpResourceConfigs) {
    if (gcpResourceConfigs[key].displayName === displayName) {
      return key;
    }
  }
  return null;
}

export const getDataSourceTypes = () => {
  return Object.keys(gcpResourceConfigs).filter(
    (key) => gcpResourceConfigs[key].isDataSource
  );
};

export const getPrioroties = () => {
  let priorities = {};
  for (const key in gcpResourceConfigs) {
    const { priority, isDataSource } = gcpResourceConfigs[key];
    priorities[key] = {
      priority: priority ? priority : 100,
      isDataSource: isDataSource ? isDataSource : false,
    };
  }
  return priorities;
};

export const tofu_versions = ["1.6.1"];
export const default_tofu_version = "1.6.1";
