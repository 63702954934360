import { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BusinessIcon from "@mui/icons-material/Business";
import { useNavigate } from "react-router-dom";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import { MaterialSymbol } from "react-material-symbols";

export default function NavMenu({ open }) {
  let history = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  return (
    <Box>
      <List
        subheader={
          <ListSubheader sx={{ opacity: open ? 1 : 0 }}>
            Dashboard
          </ListSubheader>
        }
      >
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={selectedIndex === 0}
            onClick={() => {
              handleListItemClick(0);
              history("/dashboard/overview");
            }}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Overview" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={selectedIndex === 1}
            onClick={() => {
              handleListItemClick(1);
              history("/dashboard/environments");
            }}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <MaterialSymbol
                icon="deployed_code"
                size={24}
                grade={0}
                fill={0}
                weight={400}
              />
            </ListItemIcon>
            <ListItemText
              primary="Environments"
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={selectedIndex === 7}
            onClick={() => {
              handleListItemClick(7);
              history("/dashboard/architectures");
            }}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <DesignServicesIcon />
            </ListItemIcon>
            <ListItemText
              primary="Architecture Designer"
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={selectedIndex === 6}
            onClick={() => {
              handleListItemClick(6);
              history("/dashboard/deployments");
            }}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <MaterialSymbol
                icon="cloud_upload"
                size={24}
                grade={0}
                fill={0}
                weight={400}
              />
            </ListItemIcon>
            <ListItemText
              primary="Deployment Queue"
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />

      <List
        subheader={
          <ListSubheader sx={{ opacity: open ? 1 : 0 }}>Settings</ListSubheader>
        }
      >
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              selected={selectedIndex === 3}
              onClick={() => {
                handleListItemClick(3);
                history("/dashboard/profile");
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <MaterialSymbol
                  icon="person"
                  size={24}
                  grade={0}
                  fill={0}
                  weight={400}
                />
              </ListItemIcon>
              <ListItemText primary="Profile" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItemButton
            selected={selectedIndex === 2}
            onClick={() => {
              handleListItemClick(2);
              history("/dashboard/organizations");
            }}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText
              primary="Organization"
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={selectedIndex === 4}
            onClick={() => {
              handleListItemClick(4);
              history("/dashboard/billing");
            }}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <MonetizationOnIcon />
            </ListItemIcon>
            <ListItemText primary="Billing" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        {/* <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={selectedIndex === 5}
            onClick={() => {
              handleListItemClick(5);
              history("/dashboard/playground");
            }}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <ArchitectureIcon />
            </ListItemIcon>
            <ListItemText primary="Playground" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem> */}
      </List>
    </Box>
  );
}
