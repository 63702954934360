import React from "react";
import { useState, useEffect } from "react";
import { styled, alpha } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import GCPResource from "./GCPResource";
import DisplayDetails from "./common/DisplayDetails";
import DeployQueue from "./DeployQueue";

import {
  ArrayFieldTemplateX,
  ObjectFieldTemplateX,
} from "./common/rjsfTemplates";

import { Form } from "@rjsf/mui";
import { TranslatableString, replaceStringParameters } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import {
  gcpResourceConfigs,
  tofu_versions,
  default_tofu_version,
} from "./gcpResourceDefintions";
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Collapse,
  Backdrop,
  CircularProgress,
  MenuItem,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { apiCall, getModuleName } from "../utils/api";
import { db } from "../firebase";
import { useAuth } from "../contexts/AuthContext";
import { useSnackbar } from "notistack";
import { doc, onSnapshot } from "firebase/firestore";
import { MaterialSymbol } from "react-material-symbols";

// This customized TreeItem to add dotted lines to the left when the tree is expanded
const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.group}`]: {
    marginLeft: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    borderLeft: `1px dotted ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

export default function Environment({
  environmentId,
  organization,
  handleCopyEnvironment,
}) {
  const [node, setNode] = useState({});
  const [expanded, setExpanded] = useState([]);
  const [expandCollapseTree, setExpandCollapseTree] = useState(true);
  const [openConfigDialog, setOpenConfigDialog] = useState(false);
  const [openCopyEnvrionmentDialog, setOpenCopyEnvironmentDialog] =
    useState(false);
  const [copyEnvironmentName, setCopyNewEnvironment] = useState(""); // This holds the name of the new environment
  const [environment, setEnvironment] = useState({
    name: "",
    architecture: {},
    id: null,
  });
  const [openDeployQueue, setOpenDeployQueue] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [globalVariables, setGlobalVariables] = useState({}); // This holds the global variables for the organization
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let unsubscribe = null;
    if (environmentId) {
      getEnvironmentTree();
      setExpanded([]);
      setExpandCollapseTree(true);
      unsubscribe = onSnapshot(
        doc(
          db,
          `organizations/${organization.id}/environments/${environmentId}/modules/1`
        ),
        (doc) => {
          const node_data = doc.data();
          if (node_data === undefined) {
            // Node data is undefined probably the node is deleted
            return;
          }
          setGlobalVariables(
            node_data.properties?.globals ? node_data.properties.globals : {}
          );
        }
      );
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
    // eslint-disable-next-line
  }, [environmentId]);

  const copyEnvironment = async () => {
    setOpenBackdrop(true);
    if (!copyEnvironmentName) {
      alert("The environment can not be an empty sting");
      return;
    }
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/environment/copy/${organization.id}/${environmentId}`,
      "POST",
      {
        new_name: copyEnvironmentName,
      },
      currentUser
    );
    if (res.status !== 200) {
      const data = await res.json();
      enqueueSnackbar(data.message, { variant: "error" });
    }
    if (res.status === 200) {
      const data = await res.json();
      enqueueSnackbar(`Succesfully added ${data.name} with id ${data.id}`, {
        variant: "success",
      });
      handleCopyEnvironment(data.id);
      setCopyNewEnvironment("");
      setOpenCopyEnvironmentDialog(false);
    }
    setOpenBackdrop(false);
  };

  const collectIds = (node) => {
    const ids = [node.id];

    for (const child of node.children) {
      ids.push(...collectIds(child));
    }

    return ids;
  };

  const expandTheTree = () => {
    let nodes = environment.architecture;
    const ids = collectIds(nodes);
    setExpanded(ids);
    setExpandCollapseTree(false);
  };

  const collapseTheTree = () => {
    setExpanded([]);
    setExpandCollapseTree(true);
  };

  const refreshNodes = async () => {
    await getEnvironmentTree();
    return;
  };

  const getEnvironmentTree = async () => {
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/environment/tree/${organization.id}/${environmentId}`,
      "GET",
      {},
      currentUser
    );
    if (res.status !== 200) {
      const data = await res.text();
      enqueueSnackbar(`Error getting environment tree: ${data}`, {
        variant: "error",
      });
    }
    if (res.status === 200) {
      const data = await res.json();
      setEnvironment(data);
    }
    setOpenBackdrop(false);
  };

  const handleToggle = (event, nodeIds) => {
    event.preventDefault();
    // This code allows the tree to be expanded only when icon or name of the GCPResource are clicked
    if (event.target.getAttribute("data-allow-toggle")) {
      setExpanded(nodeIds);
    }
  };

  const onSubmit = async (formData, module_version, tofu_version) => {
    const properties = formData;
    const modified_at = new Date().getTime();
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/environment/node/${organization.id}/${environmentId}/${node.id}`,
      "POST",
      {
        properties,
        module_version,
        tofu_version,
        modified_at,
      },
      currentUser
    );
    if (res.status !== 200) {
      const data = await res.text();
      enqueueSnackbar(`Error updating the node in the tree: ${data}`, {
        variant: "error",
      });
    }
    if (res.status === 200) {
      enqueueSnackbar(`Node updated successfully`, { variant: "success" });
    }
    setOpenBackdrop(false);
    setOpenConfigDialog(false);
    return;
  };

  const sendNodeUpstream = (node) => {
    if (node?.type === "organization" && node?.properties?.globals) {
      let sortedKeys = Object.keys(node.properties.globals)
        .sort()
        .reduce((r, k) => {
          r[k] = node.properties.globals[k];
          return r;
        }, {});
      node.properties.globals = sortedKeys;
    }
    setNode(node);
    setOpenConfigDialog(true);
  };

  const removeNode = async (nodeToRemove) => {
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/environment/node/${organization.id}/${environmentId}/${nodeToRemove.id}`,
      "DELETE",
      {},
      currentUser
    );
    if (res.status !== 200) {
      const data = await res.text();
      enqueueSnackbar(`Error removing node from the tree: ${data}`, {
        variant: "error",
      });
    }
    if (res.status === 200) {
      await getEnvironmentTree();
    }
    setOpenBackdrop(false);
    return;
  };

  const renderTree = (nodes) => (
    <StyledTreeItem
      key={nodes.id}
      nodeId={nodes.id.toString()}
      label={
        <GCPResource
          key={nodes.id}
          node={nodes}
          refreshNodes={refreshNodes}
          sendNodeUpstream={sendNodeUpstream}
          removeNode={removeNode}
          environmentId={environmentId}
          organization={organization}
          globalVariables={globalVariables}
        />
      }
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </StyledTreeItem>
  );

  const displayArchtecture = () => {
    if (!environment) {
      return <p>Not found</p>;
    }
    let r =
      Object.keys(environment.architecture).length === 0 ? (
        <p>Not found</p>
      ) : (
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon data-allow-toggle />}
          defaultExpandIcon={<ChevronRightIcon data-allow-toggle />}
          expanded={expanded}
          onNodeToggle={handleToggle}
          sx={{
            // height: 500,
            flexGrow: 1,
            maxWidth: "80%",
            overflowY: "auto",
          }}
        >
          {renderTree(environment.architecture)}
        </TreeView>
      );
    return r;
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="left">
      <Box
        display="flex"
        flexDirection="row"
        m={1}
        justifyContent="space-between"
        width="80%"
      >
        <Box m={1}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setOpenDeployQueue(true);
            }}
            sx={{ mr: 1 }}
            startIcon={
              <MaterialSymbol
                icon="cloud_upload"
                size={24}
                grade={0}
                fill={0}
                weight={400}
              />
            }
          >
            Deploy Queue
          </Button>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" mr={2}>
          <Box>
            <MaterialSymbol
              icon="content_copy"
              size={24}
              grade={0}
              fill={0}
              weight={400}
            />
          </Box>
          <Box mr={1}>
            <Link
              color="primary"
              onClick={() => setOpenCopyEnvironmentDialog(true)}
            >
              Clone
            </Link>
          </Box>
          {expandCollapseTree ? (
            <Box>
              <MaterialSymbol
                icon="expand_all"
                size={24}
                grade={0}
                fill={0}
                weight={400}
              />
            </Box>
          ) : (
            <Box>
              <MaterialSymbol
                icon="collapse_all"
                size={24}
                grade={0}
                fill={0}
                weight={400}
              />
            </Box>
          )}
          <Box mr={2}>
            <Link
              color="primary"
              onClick={expandCollapseTree ? expandTheTree : collapseTheTree}
            >
              {expandCollapseTree ? "Expand All" : "Collapse All"}
            </Link>
          </Box>
          <Dialog
            open={openCopyEnvrionmentDialog}
            onClose={() => setOpenCopyEnvironmentDialog(false)}
            aria-labelledby="form-dialog-title"
            maxWidth="lg"
            //fullWidth={true}
          >
            <DialogTitle id="form-dialog-title">
              Let's copy {environment.name} environment
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please create a new name for your clone.
              </DialogContentText>
              <TextField
                variant="standard"
                error={!copyEnvironmentName ? true : false}
                margin="normal"
                id="copyEnvironmentName"
                value={copyEnvironmentName}
                fullWidth
                onChange={(e) => setCopyNewEnvironment(e.target.value)}
                inputProps={{ pattern: "[a-zA-Z0-9]{2,15}" }}
                helperText="must match regex [a-zA-Z0-9]{2,15}"
              />
            </DialogContent>

            <DialogActions>
              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                width="100%"
              >
                <Button
                  onClick={() => setOpenCopyEnvironmentDialog(false)}
                  sx={{ ml: 1 }}
                >
                  I changed my mind
                </Button>
                <Button
                  onClick={copyEnvironment}
                  autoFocus
                  variant="contained"
                  startIcon={
                    <MaterialSymbol
                      icon="check"
                      size={24}
                      grade={0}
                      fill={0}
                      weight={400}
                    />
                  }
                >
                  Confirm
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
      <Box display="flex" m={1}>
        <DeployQueue
          organization_id={organization.id}
          environment_id={environmentId}
          openDeployQueue={openDeployQueue}
          setOpenDeployQueue={setOpenDeployQueue}
        />
        {displayArchtecture()}
        <ModuleSettingsDialog
          node={node}
          openConfigDialog={openConfigDialog}
          setOpenConfigDialog={setOpenConfigDialog}
          onSubmit={onSubmit}
          organizationId={organization.id}
          environmentId={environmentId}
        />
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 10 }}
        open={openBackdrop}
        onClick={() => setOpenBackdrop(false)}
      >
        <CircularProgress />
      </Backdrop>
    </Box>
  );
}

export function ModuleSettings({ node, onSubmit, closeDialog }) {
  const [intermidate, setIntermediate] = useState({});
  const [intermidateModuleVersion, setIntermidateModuleVersion] = useState("");
  const [intermidateTofuVersion, setIntermidateTofuVersion] = useState("");

  useEffect(() => {
    // This code is necessary to prevent the ResizeObserver error
    // source: https://github.com/mui/material-ui/issues/36909
    // Why is that error showing up? I don't know.
    // Why is this fix working? I don't know.
    // Is this a hack? Yes.
    // God help us all with the next version of Material UI.
    window.addEventListener("error", (e) => {
      //console.log(e.message);
      if (
        e.message === "ResizeObserver loop limit exceeded" ||
        e.message ===
          "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  useEffect(() => {
    setIntermediate(node?.properties ? node.properties : {});
    if (!gcpResourceConfigs[node.type]?.isDataSource) {
      setIntermidateModuleVersion(
        node?.module_version
          ? node.module_version
          : gcpResourceConfigs[node.type].versions[0]
      );
      setIntermidateTofuVersion(
        node?.tofu_version ? node.tofu_version : default_tofu_version
      );
    }

    return () => {
      setIntermediate({});
      setIntermidateModuleVersion("");
      setIntermidateTofuVersion("");
    };
  }, [node.properties, node.module_version, node.type, node.tofu_version]);

  const onChange = ({ formData }, e) => {
    setIntermediate(formData);
  };

  return (
    <Box>
      {!gcpResourceConfigs[node.type]?.isDataSource && (
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "15ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            variant="outlined"
            margin="dense"
            size="small"
            id="ModuleVersion"
            label="Module Version"
            value={intermidateModuleVersion}
            onChange={(e) => setIntermidateModuleVersion(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="dense"
            size="small"
            id="TofuVersion"
            label="Tofu Version"
            value={intermidateTofuVersion}
            select
            onChange={(e) => setIntermidateTofuVersion(e.target.value)}
          >
            {tofu_versions.map((version) => (
              <MenuItem key={version} value={version}>
                {version}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      )}
      <Form
        schema={gcpResourceConfigs[node.type].schema}
        formData={intermidate}
        onSubmit={({ formData }, e) => {
          onSubmit(formData, intermidateModuleVersion, intermidateTofuVersion);
        }}
        uiSchema={
          "uiSchema" in gcpResourceConfigs[node.type]
            ? gcpResourceConfigs[node.type].uiSchema
            : {}
        }
        validator={validator}
        templates={{
          ArrayFieldTemplate: ArrayFieldTemplateX,
          ObjectFieldTemplate: ObjectFieldTemplateX,
        }}
        onChange={onChange}
        translateString={(str, params) => {
          switch (str) {
            case TranslatableString.NewStringDefault:
              return ""; // Use an empty string for the new additionalProperties string default value
            case TranslatableString.KeyLabel:
              return replaceStringParameters("%1", params); //
            default:
              return str;
          }
        }}
      >
        {
          <Box sx={{ m: 1 }}>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={closeDialog}
              sx={{ ml: 1 }}
            >
              Cancel
            </Button>
          </Box>
        }
      </Form>
    </Box>
  );
}

export function ModuleSettingsDialog({
  node,
  openConfigDialog,
  setOpenConfigDialog,
  onSubmit,
  organizationId,
  environmentId,
}) {
  const [openOutputDialog, setOpenOutputDialog] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [downloadedNode, setDownloadedNode] = useState({});

  useEffect(() => {
    if (openConfigDialog) {
      getNodeDetails();
    }
    // eslint-disable-next-line
  }, [openConfigDialog]);

  const getNodeDetails = async () => {
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/environment/node/${organizationId}/${environmentId}/${node.id}`,
      "GET",
      {},
      currentUser
    );
    if (res.status !== 200) {
      const data = await res.text();
      enqueueSnackbar(`Error fetching node ${node.id}: ${data}`, {
        variant: "error",
      });
    }
    if (res.status === 200) {
      const data = await res.json();
      setDownloadedNode(data);
    }
    setOpenBackdrop(false);
  };
  return (
    <>
      <Dialog
        open={openConfigDialog}
        onClose={() => setOpenConfigDialog(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        //fullWidth
        //fullScreen
      >
        <AppBar sx={{ position: "sticky" }} component="nav">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpenConfigDialog(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {Object.keys(node).length > 0 && (
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {gcpResourceConfigs[node.type].displayName} ( {node.type} )
              </Typography>
            )}
            {Object.keys(node).length > 0 && (
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {getModuleName(node)}
              </Typography>
            )}
            <Button
              autoFocus
              color="inherit"
              onClick={() => setOpenOutputDialog(true)}
            >
              Outputs
            </Button>
            <Dialog
              open={openOutputDialog}
              onClose={() => setOpenOutputDialog(false)}
              maxWidth="lg"
            >
              <AppBar sx={{ position: "sticky" }} component="nav">
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => setOpenOutputDialog(false)}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                  >
                    Available terraform outputs for deployed modules
                  </Typography>
                </Toolbar>
              </AppBar>
              <DialogContent>
                {openOutputDialog && (
                  <OutputMenu
                    organizationId={organizationId}
                    environmentId={environmentId}
                  />
                )}
              </DialogContent>
            </Dialog>
          </Toolbar>
        </AppBar>
        <DialogActions>
          {Object.keys(downloadedNode).length > 0 && (
            <ModuleSettings
              node={downloadedNode}
              onSubmit={onSubmit}
              closeDialog={() => setOpenConfigDialog(false)}
            />
          )}
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={openBackdrop}
        onClick={() => setOpenBackdrop(false)}
      >
        <CircularProgress />
      </Backdrop>
    </>
  );
}

export function OutputMenu({ organizationId, environmentId }) {
  const [open, setOpen] = useState(false);
  const [outputs, setOutputs] = useState([]);
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const handleClick = () => {
    setOpen(!open);
  };
  useEffect(() => {
    if (environmentId) {
      getOutputs();
    }
    // eslint-disable-next-line
  }, [environmentId]);

  const getOutputs = async () => {
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/environment/outputs/${organizationId}/${environmentId}`,
      "GET",
      {},
      currentUser
    );
    if (res.status !== 200) {
      const data = await res.text();
      enqueueSnackbar(`Error creating customer portal: ${data}`, {
        variant: "error",
      });
    }
    if (res.status === 200) {
      const data = await res.json();
      setOutputs(data);
    }
  };

  const convert = (output) => {
    if ("outputs" in output) {
      if (typeof output.outputs === "string") {
        let decoded_outputs = JSON.parse(atob(output.outputs));
        return decoded_outputs;
      }
    }
    return "";
  };

  return (
    // In some rare cases, the node is null.
    <DialogContent>
      {outputs.map((output, index) => (
        <>
          <DialogTitle
            component="div"
            sx={{
              display: "flex",
              flexDirection: "row",
              flexGrow: 1,
            }}
            key={`output-dialog-title-${index}`}
          >
            <IconButton
              onClick={handleClick}
              key={`output-icon-button-${index}`}
            >
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
            <DisplayDetails
              componentName="Module Name"
              componentValue={output.name}
              key={`display-details-name-${index}`}
            />
            <DisplayDetails
              componentName="Module Type"
              componentValue={gcpResourceConfigs[output.type].displayName}
              key={`display-details-type-${index}`}
            />
          </DialogTitle>
          <Collapse in={open}>
            <DialogContentText key={`dialog-content-text-${index}`}>
              <pre>{JSON.stringify(convert(output), null, 2)}</pre>
            </DialogContentText>
          </Collapse>
        </>
      ))}
    </DialogContent>
  );
}
