import { React, useEffect } from "react";
import {
  ArrayFieldTemplateX,
  ObjectFieldTemplateX,
} from "../common/rjsfTemplates";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  AppBar,
  Typography,
  IconButton,
  Toolbar,
  TextField,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Form } from "@rjsf/mui";
import { TranslatableString, replaceStringParameters } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import {
  gcpResourceConfigs,
  default_tofu_version,
} from "../gcpResourceDefintions";
import { getModuleName } from "../../utils/api";

export function QueueResourceModuleSettings({ node, closeDialog }) {
  useEffect(() => {
    // This code is necessary to prevent the ResizeObserver error
    // source: https://github.com/mui/material-ui/issues/36909
    // Why is that error showing up? I don't know.
    // Why is this fix working? I don't know.
    // Is this a hack? Yes.
    // God help us all with the next version of Material UI.
    window.addEventListener("error", (e) => {
      //console.log(e.message);
      if (
        e.message === "ResizeObserver loop limit exceeded" ||
        e.message ===
          "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);
  return (
    <Box>
      {!gcpResourceConfigs[node.type]?.isDataSource && (
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "15ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            variant="outlined"
            margin="dense"
            size="small"
            id="ModuleVersion"
            label="Module Version"
            value={
              node?.module_version
                ? node.module_version
                : gcpResourceConfigs[node.type].versions[0]
            }
          />
          <TextField
            variant="outlined"
            margin="dense"
            size="small"
            id="TofuVersion"
            label="Tofu Version"
            value={
              node?.tofu_version ? node.tofu_version : default_tofu_version
            }
            select
          >
            <MenuItem
              key="menu-item"
              value={
                node?.tofu_version ? node.tofu_version : default_tofu_version
              }
            >
              {node?.tofu_version ? node.tofu_version : default_tofu_version}
            </MenuItem>
          </TextField>
        </Box>
      )}
      <Form
        schema={gcpResourceConfigs[node.type].schema}
        formData={
          node.hasOwnProperty("properties")
            ? node.properties
              ? node.properties
              : {}
            : {}
        }
        //onSubmit={onSubmit}
        uiSchema={
          "uiSchema" in gcpResourceConfigs[node.type]
            ? gcpResourceConfigs[node.type].uiSchema
            : {}
        }
        validator={validator}
        templates={{
          ArrayFieldTemplate: ArrayFieldTemplateX,
          ObjectFieldTemplate: ObjectFieldTemplateX,
        }}
        translateString={(str, params) => {
          switch (str) {
            case TranslatableString.NewStringDefault:
              return ""; // Use an empty string for the new additionalProperties string default value
            case TranslatableString.KeyLabel:
              return replaceStringParameters("%1", params); //
            default:
              return str;
          }
        }}
      >
        {
          <Box sx={{ m: 1 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => closeDialog(false)}
              sx={{ ml: 1 }}
            >
              Close
            </Button>
          </Box>
        }
      </Form>
    </Box>
  );
}

export function QueueResourceModuleSettingsDialog({
  node,
  openConfigDialog,
  setOpenConfigDialog,
}) {
  return (
    <Dialog
      open={openConfigDialog}
      onClose={() => setOpenConfigDialog(false)}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      //fullWidth
      //fullScreen
    >
      <AppBar sx={{ position: "sticky" }} component="nav">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpenConfigDialog(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          {Object.keys(node).length > 0 && (
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {gcpResourceConfigs[node.type].displayName} ( {node.type} )
            </Typography>
          )}
          {Object.keys(node).length > 0 && (
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {node.properties
                ? getModuleName(node)
                : "ERROR No Input Properties Found"}
            </Typography>
          )}
        </Toolbar>
      </AppBar>
      <DialogActions>
        <QueueResourceModuleSettings
          node={node}
          closeDialog={setOpenConfigDialog}
        />
      </DialogActions>
    </Dialog>
  );
}
