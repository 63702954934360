import { React, useState, useEffect } from "react";
import {
  AppBar,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  TextField,
  Select,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { MaterialSymbol } from "react-material-symbols";
import ArchitectureTemplate from "./ArchitectureTemplate";
import ArchitectureTemplatesList from "./ArchitecureTemplatesList";
import { fetchTemplates } from "../utils/db";
import { useAuth } from "../contexts/AuthContext";
import { useSnackbar } from "notistack";
import { apiCall } from "../utils/api";

export default function ArchitectureTemplateBuilder() {
  const [org, setOrg] = useState(null); // This holds currently selected organization id
  const [templates, setTemplates] = useState([]);
  const [newTemplate, setNewTemplate] = useState(""); // This holds the name of the new environment
  const [openTemplateName, setOpenTemplateName] = useState(false);
  const { organizations, currentUser } = useAuth();
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const organizationChange = async (org_id) => {
    setOrg(organizations.find((org) => org.id === org_id));
    await getTemplateNamesForOrg(org_id);
  };

  useEffect(() => {
    if (organizations.length === 0) {
      return;
    }
    setOrg(organizations[0]);
    getTemplateNamesForOrg(organizations[0].id);
    // eslint-disable-next-line
  }, [organizations]);

  const getTemplateNamesForOrg = async (orgId) => {
    if (!orgId) {
      return;
    }
    const temps = await fetchTemplates(orgId);
    let reduce_temps = temps.map((temp) => ({
      name: temp.name,
      id: temp.id,
      description: temp.description,
      created_at: temp.created_at,
      modified_at: temp.modified_at,
    }));
    setTemplates(reduce_temps);
    if (reduce_temps.length > 0) {
      setSelectedTemplate(reduce_temps[0].id);
    } else {
      setSelectedTemplate(null);
    }
  };

  const addNewTemplate = async () => {
    if (!newTemplate) {
      alert("The template can not be an empty sting");
      return;
    }
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/template/${org.id}`,
      "POST",
      {
        name: newTemplate,
      },
      currentUser
    );
    if (res.status !== 200) {
      const data = await res.json();
      enqueueSnackbar(data.message, { variant: "error" });
    }
    if (res.status === 200) {
      const data = await res.json();
      enqueueSnackbar(`Succesfully added ${data.name} with id ${data.id}`, {
        variant: "success",
      });
      setNewTemplate("");
      let newTemps = await fetchTemplates(org.id);
      setTemplates(newTemps);
      setOpenTemplateName(false);
    }
    setOpenBackdrop(false);
  };

  const handleDeleteTemplate = async (organization_id, template_id) => {
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/template/${organization_id}/${template_id}`,
      "DELETE",
      {},
      currentUser
    );
    if (res.status !== 200) {
      let error = await res.text();
      enqueueSnackbar(`Error ${error}`, { variant: "error" });
    }
    if (res.status === 200) {
      let response = await res.json();
      enqueueSnackbar(`${response.message}`, { variant: "success" });
      await getTemplateNamesForOrg(organization_id);
    }
    setOpenBackdrop(false);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
    >
      <AppBar position="static" color="default">
        <Box display="flex" flexDirection="row" m={1}>
          <OrganizationsList
            organizations={organizations}
            organizationChange={organizationChange}
          />
          <Box
            display="flex"
            flexDirection="row"
            m={1}
            justifyContent="flex-end"
          >
            <Box m={1}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setOpenTemplateName(true)}
                startIcon={
                  <MaterialSymbol
                    icon="add"
                    size={24}
                    grade={0}
                    fill={0}
                    weight={400}
                  />
                }
              >
                Add Architecture
              </Button>
              <Dialog
                open={openTemplateName}
                onClose={() => setOpenTemplateName(false)}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  Add New Architecture
                </DialogTitle>
                <DialogContent>
                  <TextField
                    variant="standard"
                    error={!newTemplate ? true : false}
                    margin="normal"
                    id="templateName"
                    label="New Template Name"
                    value={newTemplate}
                    fullWidth
                    onChange={(e) => setNewTemplate(e.target.value)}
                    inputProps={{ pattern: "[a-zA-Z0-9]{2,15}" }}
                    helperText="must match regex [a-zA-Z0-9]{2,15}"
                  />
                </DialogContent>
                <DialogActions>
                  <Box
                    justifyContent="space-between"
                    alignItems="center"
                    display="flex"
                    width="100%"
                  >
                    <Button
                      onClick={() => setOpenTemplateName(false)}
                      color="primary"
                      sx={{ ml: 1 }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={addNewTemplate}
                      color="primary"
                      variant="contained"
                      startIcon={
                        <MaterialSymbol
                          icon="check"
                          size={24}
                          grade={0}
                          fill={0}
                          weight={400}
                        />
                      }
                    >
                      Confirm
                    </Button>
                  </Box>
                </DialogActions>
              </Dialog>
            </Box>
          </Box>
        </Box>
      </AppBar>
      {templates.length > 0 && (
        <ArchitectureTemplatesList
          organization={org}
          rows={templates}
          setSelectedTemplate={setSelectedTemplate}
          handleDeleteTemplate={handleDeleteTemplate}
        />
      )}
      {selectedTemplate && (
        <ArchitectureTemplate
          templateId={selectedTemplate}
          organization={org}
          getTemplateNamesForOrg={getTemplateNamesForOrg} //Piggy
        />
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 10 }}
        open={openBackdrop}
        onClick={() => setOpenBackdrop(false)}
      >
        <CircularProgress />
      </Backdrop>
    </Box>
  );
}

export function OrganizationsList({ organizations, organizationChange }) {
  const [state, setState] = useState("");

  const handleChange = (event) => {
    setState(event.target.value);
    organizationChange(event.target.value);
  };
  useEffect(() => {
    if (organizations.length > 0) {
      setState(organizations[0].id);
    }
  }, [organizations]);

  return (
    <FormControl
      variant="standard"
      sx={{ margin: (theme) => theme.spacing(1), minWidth: 120 }}
    >
      <InputLabel id="demo-simple-select-label">Organization</InputLabel>
      <Select variant="standard" value={state} onChange={handleChange}>
        {organizations.map((org, index) => (
          <MenuItem key={index} value={org.id}>
            {org.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
