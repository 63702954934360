import { React, useState, useEffect } from "react";
import { Box, Link } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import { gcpResourceConfigs } from "./gcpResourceDefintions";
import ArchitecureTemplateResourceButtonGroup from "./ArchitectureTemplateResourceButtonGroup";
import DisplayDetails from "./common/DisplayDetails";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

// This allows us to use sx={} instead of className={} for the img tag
const Img = styled("img")``;

export function TemplateCloudModule({
  node,
  refreshNodes,
  sendNodeUpstream,
  removeNode,
  isAdmin,
  templateId,
  organization,
  globalVariables,
}) {
  const [moduleUrl, setModuleUrl] = useState(null);
  const [nodeName, setNodeName] = useState("");
  const [nodeData, setNodeData] = useState({
    id: 1,
    type: "organization",
    properties: {},
    parent: null,
    children: [],
    module_version: "",
  });
  const theme = useTheme();

  useEffect(() => {
    let unsubscribe_node = null;
    unsubscribe_node = onSnapshot(
      doc(
        db,
        `organizations/${organization.id}/templates/${templateId}/modules`,
        node.id
      ),
      (doc) => {
        const node_data = doc.data();
        if (node_data === undefined) {
          // Node data is undefined probably the node is deleted
          return;
        }
        setNodeData(node_data);
      }
    );
    if ("url" in gcpResourceConfigs[node.type]) {
      setModuleUrl(gcpResourceConfigs[node.type].url);
    } else {
      setModuleUrl(null);
    }
    return () => {
      if (unsubscribe_node !== null) {
        unsubscribe_node();
      }
    };
    // eslint-disable-next-line
  }, [node]);

  useEffect(() => {
    updateNodeName();
    // eslint-disable-next-line
  }, [nodeData, globalVariables]);

  const updateNodeName = () => {
    let field = gcpResourceConfigs[nodeData.type]?.displayField;
    if (nodeData?.properties) {
      if (field in nodeData.properties) {
        let value = nodeData.properties[field];
        if (value.startsWith("$_")) {
          if (!globalVariables) {
            setNodeName(value);
            return;
          }
          const key = value.slice(2);
          const globalValue = globalVariables[key];
          if (globalValue !== undefined) {
            // Use the global value
            return setNodeName(globalValue);
          } else {
            // Handle the case where the global value is undefined
            setNodeName(value);
            return;
          }
        }
        return setNodeName(value);
      }
    }
  };

  const isNodeDataSource = (node_type) => {
    const { isDataSource } = gcpResourceConfigs[node_type];
    if (isDataSource) {
      return isDataSource;
    }
    return false;
  };

  const setBackgroundImage = (node) => {
    if (isNodeDataSource(node.type)) {
      return theme.palette.mode === "dark"
        ? "linear-gradient(to bottom right, lightblue, black)"
        : "linear-gradient(to bottom right, lightblue, white)";
    } else {
      return null;
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          border: 1,
          borderColor: "grey.500",
          ml: 1,
          mr: 1,
          mt: 1,
          mb: 1,
          backgroundImage: (theme) => setBackgroundImage(node),
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "25px",
            height: "25px",
            margin: 1,
          }}
        >
          <Img
            src={gcpResourceConfigs[node.type].icon}
            alt="resource icon"
            data-allow-toggle
            sx={{ display: "flex", width: "100%", height: "100%" }}
          ></Img>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexGrow: 1,
            }}
          >
            {node.type !== "organization" && (
              <DisplayDetails
                componentName="Module Type:"
                componentValue={gcpResourceConfigs[node.type].displayName}
                bottomMargin={moduleUrl ? false : true}
              />
            )}
            {node.type !== "organization" &&
              node.type !== "project_source" &&
              node.type !== "folder_source" && (
                <DisplayDetails
                  componentName="Name:"
                  componentValue={nodeName}
                  bottomMargin={moduleUrl ? false : true}
                />
              )}
            {!isNodeDataSource(node.type) && (
              <DisplayDetails
                componentName="Version:"
                componentValue={
                  node?.module_version
                    ? node.module_version
                    : gcpResourceConfigs[node.type].versions[0]
                }
                bottomMargin={moduleUrl ? false : true}
              />
            )}
            {node.type === "organization" && (
              <DisplayDetails
                componentName="Name:"
                componentValue="Root of the Architecture"
                bottomMargin={moduleUrl ? false : true}
              />
            )}
            {node.type === "organization" && (
              <DisplayDetails
                componentName="Global Variables:"
                componentValue="Set Global Variables at this level"
                bottomMargin={moduleUrl ? false : true}
              />
            )}
            {node.type === "project_source" && (
              <DisplayDetails
                componentName="Project ID:"
                componentValue={nodeName}
                bottomMargin={moduleUrl ? false : true}
              />
            )}
            {node.type === "project_source" && (
              <DisplayDetails
                componentName="Info:"
                componentValue="Already deployed project"
                bottomMargin={moduleUrl ? false : true}
              />
            )}
            {node.type === "folder_source" && (
              <DisplayDetails
                componentName="Folder ID:"
                componentValue={nodeName}
                bottomMargin={moduleUrl ? false : true}
              />
            )}
            {node.type === "folder_source" && (
              <DisplayDetails
                componentName="Info:"
                componentValue="Already created folder"
                bottomMargin={moduleUrl ? false : true}
              />
            )}
          </Box>
          {moduleUrl && (
            <Box
              alignItems="center"
              justifyContent="left"
              ml={1}
              display="flex"
            >
              <Link
                href={moduleUrl}
                variant="caption"
                fontSize={9}
                underline="hover"
                rel="noreferrer"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(moduleUrl, "_blank");
                }}
              >
                {moduleUrl}
              </Link>
            </Box>
          )}
        </Box>
        <ArchitecureTemplateResourceButtonGroup
          key={node.id}
          node={node}
          refreshNodes={refreshNodes}
          sendNodeUpstream={sendNodeUpstream}
          removeNode={removeNode}
          isAdmin={isAdmin}
          organization={organization}
          templateId={templateId}
        />
      </Box>
    </Box>
  );
}
