import { React, useEffect, useState } from "react";
import { Box, Link } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import { gcpResourceConfigs } from "../gcpResourceDefintions";
import QueueResourceButtonGroup from "./QueueResourceButtonGroup";
import DisplayDetails from "../common/DisplayDetails";
import LinearProgress from "@mui/material/LinearProgress";

// This allows us to use sx={} instead of className={} for the img tag
const Img = styled("img")``;

export default function QueueResource({
  node,
  sendNodeUpstream,
  globalVariables,
}) {
  const [moduleStatus, setModuleStatus] = useState("NOT-STARTED");
  const [moduleUrl, setModuleUrl] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    if ("url" in gcpResourceConfigs[node.type]) {
      setModuleUrl(gcpResourceConfigs[node.type].url);
    } else {
      setModuleUrl(null);
    }
  }, [node]);

  useEffect(() => {
    const { status } = node;
    if (status) {
      setModuleStatus(status);
    } else {
      setModuleStatus("NOT-STARTED");
    }
  }, [node]);

  const getName = (node) => {
    if ("displayField" in gcpResourceConfigs[node.type]) {
      let field = gcpResourceConfigs[node.type].displayField;
      if ("properties" in node) {
        // This use case can happen when a new node is added but not yet set with properties
        if (!node.properties) {
          return "";
        }
        if (field in node.properties) {
          let value = node.properties[field];
          if (value.startsWith("$_")) {
            let globals = globalVariables;
            const key = value.slice(2);
            const globalValue = globals[key];
            if (globalValue !== undefined) {
              // Use the global value
              return globalValue;
            } else {
              // Handle the case where the global value is undefined
              return value;
            }
          }
          return value;
        } else {
          return "";
        }
      }
    }
    return "";
  };

  const isNodeDataSource = (node_type) => {
    const { isDataSource } = gcpResourceConfigs[node_type];
    if (isDataSource) {
      return isDataSource;
    }
    return false;
  };

  const setBackgroundImage = (node) => {
    if (node.type === "organization") {
      return theme.palette.mode === "dark"
        ? "linear-gradient(to bottom right, lightblue, black)"
        : "linear-gradient(to bottom right, lightblue, white)";
    }
    if (
      moduleStatus.includes("SUCCESS") ||
      moduleStatus.includes("WORKING") ||
      moduleStatus.includes("PROGRESS")
    ) {
      return theme.palette.mode === "dark"
        ? "linear-gradient(to bottom right, green, black)"
        : "linear-gradient(to bottom right, lightgreen, white)";
    }
    if (moduleStatus.includes("FAILURE")) {
      return theme.palette.mode === "dark"
        ? "linear-gradient(to bottom right, coral, black)"
        : "linear-gradient(to bottom right, lightcoral, white)";
    }
    return null;
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          border: 1,
          borderColor: "grey.500",
          ml: 1,
          mr: 1,
          mt: 1,
          mb: (theme) =>
            moduleStatus.includes("WORKING") ||
            moduleStatus.includes("PROGRESS")
              ? 0
              : 1,
          backgroundImage: (theme) => setBackgroundImage(node),
          //height: "20",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "25px",
            height: "25px",
            margin: 1,
          }}
        >
          <Img
            src={gcpResourceConfigs[node.type].icon}
            alt="resource icon"
            data-allow-toggle
            sx={{ display: "flex", width: "100%", height: "100%" }}
          ></Img>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexGrow: 1,
            }}
          >
            {node.type !== "organization" && (
              <DisplayDetails
                componentName="Module Type:"
                componentValue={gcpResourceConfigs[node.type].displayName}
                bottomMargin={moduleUrl ? false : true}
              />
            )}
            {node.type !== "organization" &&
              node.type !== "project_source" &&
              node.type !== "folder_source" && (
                <DisplayDetails
                  componentName="Name:"
                  componentValue={getName(node)}
                  bottomMargin={moduleUrl ? false : true}
                />
              )}
            {!isNodeDataSource(node.type) && (
              <DisplayDetails
                componentName="Version:"
                componentValue={
                  node?.module_version
                    ? node.module_version
                    : gcpResourceConfigs[node.type].versions[0]
                }
                bottomMargin={moduleUrl ? false : true}
              />
            )}
            {!isNodeDataSource(node.type) && (
              <DisplayDetails
                componentName="Deployment Status:"
                componentValue={moduleStatus}
                bottomMargin={moduleUrl ? false : true}
              />
            )}
            {node.type === "organization" && (
              <DisplayDetails
                componentName="Name:"
                componentValue="Root of the Architecture"
                bottomMargin={moduleUrl ? false : true}
              />
            )}
            {node.type === "organization" && (
              <DisplayDetails
                componentName="Global Variables:"
                componentValue="Set Global Variables at this level"
                bottomMargin={moduleUrl ? false : true}
              />
            )}
            {node.type === "project_source" && (
              <DisplayDetails
                componentName="Project ID:"
                componentValue={getName(node)}
                bottomMargin={moduleUrl ? false : true}
              />
            )}
            {node.type === "project_source" && (
              <DisplayDetails
                componentName="Info:"
                componentValue="Already deployed project"
                bottomMargin={moduleUrl ? false : true}
              />
            )}
            {node.type === "folder_source" && (
              <DisplayDetails
                componentName="Folder ID:"
                componentValue={getName(node)}
                bottomMargin={moduleUrl ? false : true}
              />
            )}
            {node.type === "folder_source" && (
              <DisplayDetails
                componentName="Info:"
                componentValue="Already created folder"
                bottomMargin={moduleUrl ? false : true}
              />
            )}
          </Box>
          {moduleUrl && (
            <Box
              alignItems="center"
              justifyContent="left"
              ml={1}
              display="flex"
            >
              <Link
                href={moduleUrl}
                variant="caption"
                fontSize={9}
                underline="hover"
                rel="noreferrer"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(moduleUrl, "_blank");
                }}
              >
                {moduleUrl}
              </Link>
            </Box>
          )}
        </Box>
        <QueueResourceButtonGroup
          key={node.id}
          node={node}
          sendNodeUpstream={sendNodeUpstream}
        />
      </Box>
      <ProgressLine node={node} />
    </Box>
  );
}

function ProgressLine({ node }) {
  const isJobInProgress = () => {
    const { status } = node;
    if (status) {
      return (
        status.includes("WORKING") ||
        status.includes("QUEUED") ||
        status.includes("PROGRESS")
      );
    }
    return false;
  };

  const linearProgress = () => (
    <Box sx={{ ml: 1, mr: 1, mb: 1 }}>
      <LinearProgress />
    </Box>
  );

  return isJobInProgress() ? linearProgress() : <></>;
}
