import React from "react";
import { Typography, Link } from "@mui/material";

export default function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      sx={{ fontSize: "13px" }}
    >
      {"Copyright "}
      <Link
        color="inherit"
        href="https://www.mightycloud.ai/"
        underline="hover"
      >
        mightycloud.ai
      </Link>
      {" © 2024"}
    </Typography>
  );
}
