import { React, useState, useEffect } from "react";
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  TextField,
  Select,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Environment from "./Environment";
import {
  fetchEnvironments,
  fetchBlueprints,
  fetchTemplates,
} from "../utils/db";
import { useAuth } from "../contexts/AuthContext";
import { apiCall } from "../utils/api";
import { useSnackbar } from "notistack";
import { MaterialSymbol } from "react-material-symbols";

export default function Environments() {
  const [value, setValue] = useState(0);
  const [org, setOrg] = useState(null); // This holds currently selected organization id
  const [environments, setEnvironments] = useState([]);
  const [newEnvironment, setNewEnvironment] = useState(""); // This holds the name of the new environment
  const [openEnvName, setOpenEnvName] = useState(false);
  const [openBluerprints, setOpenBlueprints] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { organizations, currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  /*
   * This handles change in tab selection
   */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCopyEnvironment = async (newEnvironmentID) => {
    const envs = await fetchEnvironments(org.id);
    let reduce_envs = envs.map((env) => ({ name: env.name, id: env.id }));
    setEnvironments(reduce_envs);
    const index_of_new_env = reduce_envs.findIndex(
      (env) => env.id === newEnvironmentID
    );
    setValue(index_of_new_env);
  };

  const organizationChange = async (org_id) => {
    setOrg(organizations.find((org) => org.id === org_id));
    await getEnvironmentNamesForOrg(org_id);
  };

  useEffect(() => {
    if (organizations.length === 0) {
      return;
    }
    setOrg(organizations[0]);
    getEnvironmentNamesForOrg(organizations[0].id);
    // eslint-disable-next-line
  }, [organizations]);

  const getEnvironmentNamesForOrg = async (orgId) => {
    if (!orgId) {
      return;
    }
    const envs = await fetchEnvironments(orgId);
    let reduce_envs = envs.map((env) => ({ name: env.name, id: env.id }));
    setEnvironments(reduce_envs);
    setValue(0);
  };

  const addEnv = async () => {
    if (!newEnvironment) {
      alert("The environment can not be an empty sting");
      return;
    }

    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/environment/${org.id}`,
      "POST",
      {
        name: newEnvironment,
      },
      currentUser
    );
    if (res.status !== 200) {
      const data = await res.json();
      enqueueSnackbar(data.message, { variant: "error" });
    }
    if (res.status === 200) {
      const data = await res.json();
      enqueueSnackbar(`Succesfully added ${data.name} with id ${data.id}`, {
        variant: "success",
      });
      let newEnvs = await fetchEnvironments(org.id);
      let reduce_envs = newEnvs.map((env) => ({ name: env.name, id: env.id }));
      setEnvironments(reduce_envs);
      const index_of_new_env = reduce_envs.findIndex(
        (env) => env.id === data.id
      );
      setValue(index_of_new_env);
      setNewEnvironment("");
      setOpenEnvName(false);
    }
    setOpenBackdrop(false);
  };

  const addEnvrionmentFromBlueprint = async (environmentName, blueprint) => {
    if (!environmentName) {
      alert("The environment can not be an empty sting");
      return;
    }
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/environment/${org.id}`,
      "POST",
      {
        name: environmentName,
        source_id: blueprint.source_id,
        from_blueprint: true,
        id: blueprint.id,
      },
      currentUser
    );
    if (res.status !== 200) {
      const data = await res.json();
      enqueueSnackbar(data.message, { variant: "error" });
    }
    if (res.status === 200) {
      const data = await res.json();
      enqueueSnackbar(
        `Succesfully added ${data.name} with id ${data.id} using blueprint ${blueprint.name}`,
        {
          variant: "success",
        }
      );
      let newEnvs = await fetchEnvironments(org.id);
      let reduce_envs = newEnvs.map((env) => ({ name: env.name, id: env.id }));
      setEnvironments(reduce_envs);
      const index_of_new_env = reduce_envs.findIndex(
        (env) => env.id === data.id
      );
      setValue(index_of_new_env);
      setNewEnvironment("");
      setOpenBlueprints(false);
    }
    setOpenBackdrop(false);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
    >
      <AppBar position="static" color="default">
        <Box display="flex" flexDirection="row" m={1}>
          <OrganizationsList
            organizations={organizations}
            organizationChange={organizationChange}
          />
          <Box
            display="flex"
            flexDirection="row"
            m={1}
            justifyContent="flex-end"
          >
            <Box m={1}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setOpenEnvName(true)}
                startIcon={
                  <MaterialSymbol
                    icon="add"
                    size={24}
                    grade={0}
                    fill={0}
                    weight={400}
                  />
                }
              >
                Add Environment
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setOpenBlueprints(true);
                }}
                sx={{ ml: 1 }}
                startIcon={
                  <MaterialSymbol
                    icon="list_alt_add"
                    size={24}
                    grade={0}
                    fill={0}
                    weight={400}
                  />
                }
              >
                Use Blueprint
              </Button>
              <Dialog
                open={openEnvName}
                onClose={() => setOpenEnvName(false)}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  Add New Environment
                </DialogTitle>
                <DialogContent>
                  <TextField
                    variant="standard"
                    error={!newEnvironment ? true : false}
                    margin="normal"
                    id="environmentName"
                    label="New Environment Name"
                    value={newEnvironment}
                    fullWidth
                    onChange={(e) => setNewEnvironment(e.target.value)}
                    inputProps={{ pattern: "[a-zA-Z0-9]{2,15}" }}
                    helperText="must match regex [a-zA-Z0-9]{2,15}"
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setOpenEnvName(false)}
                    color="primary"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={addEnv}
                    color="primary"
                    variant="contained"
                    startIcon={
                      <MaterialSymbol
                        icon="add"
                        size={24}
                        grade={0}
                        fill={0}
                        weight={400}
                      />
                    }
                  >
                    Add
                  </Button>
                </DialogActions>
              </Dialog>
              <BlueprintsMenu
                open={openBluerprints}
                setOpen={setOpenBlueprints}
                organization={org}
                addEnvrionmentFromBlueprint={addEnvrionmentFromBlueprint}
              />
            </Box>
          </Box>
        </Box>
      </AppBar>
      <AppBar
        position="static"
        color="default"
        style={{ display: "flex", direction: "column" }}
      >
        <Box display="flex" flexDirection="row">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
          >
            {environments.map((env, index) => (
              <Tab
                key={index}
                label={env.name}
                id={`scrollable-auto-tab-${index}`}
              />
            ))}
          </Tabs>
        </Box>
      </AppBar>
      {environments.length > 0 && (
        <Environment
          environmentId={environments[value].id}
          organization={org}
          handleCopyEnvironment={handleCopyEnvironment}
        />
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 10 }}
        open={openBackdrop}
        onClick={() => setOpenBackdrop(false)}
      >
        <CircularProgress />
      </Backdrop>
    </Box>
  );
}

export function OrganizationsList({ organizations, organizationChange }) {
  const [state, setState] = useState("");

  const handleChange = (event) => {
    setState(event.target.value);
    organizationChange(event.target.value);
  };
  useEffect(() => {
    if (organizations.length > 0) {
      setState(organizations[0].id);
    }
  }, [organizations]);

  return (
    <FormControl
      variant="standard"
      sx={{ margin: (theme) => theme.spacing(1), minWidth: 120 }}
    >
      <InputLabel id="demo-simple-select-label">Organization</InputLabel>
      <Select variant="standard" value={state} onChange={handleChange}>
        {organizations.map((org, index) => (
          <MenuItem key={index} value={org.id}>
            {org.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const columns = [
  {
    id: 0,
    field: "name",
    width: 200,
  },
  {
    id: 1,
    field: "id",
    width: 200,
  },
  {
    id: 2,
    field: "description",
    width: 250,
  },
  {
    id: 3,
    field: "source",
    width: 150,
  },
  {
    id: 4,
    field: "created_at",
    width: 200,
  },
  {
    id: 5,
    field: "modified_at",
    width: 200,
  },
];

function BlueprintsMenu({
  organization,
  open,
  setOpen,
  addEnvrionmentFromBlueprint,
}) {
  const [newEnvironment, setNewEnvironment] = useState("");
  const [blueprints, setBlueprints] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    if (organization) {
      getAllBlueprints();
    }
    // eslint-disable-next-line
  }, [organization]);

  const addEnvironment = () => {
    if (!selectedRow) {
      alert("Please select a blueprint");
      return;
    }
    addEnvrionmentFromBlueprint(newEnvironment, selectedRow);
    setNewEnvironment("");
  };

  const getAllBlueprints = async () => {
    if (!organization) {
      return;
    }
    const blueprints_fetch = await fetchBlueprints();
    const templates = await fetchTemplates(organization.id);
    const template_modified = templates.map((template) => ({
      ...template,
      source: organization.name,
      source_id: organization.id,
    }));
    const all_bluprints = blueprints_fetch.concat(template_modified);
    setBlueprints(
      all_bluprints.map((blueprint) => ({
        ...blueprint,
        created_at: blueprint.created_at
          ? new Date(blueprint.created_at).toLocaleString()
          : "",
        modified_at: blueprint.modified_at
          ? new Date(blueprint.modified_at).toLocaleString()
          : "",
      }))
    );
  };
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg" fullWidth>
      <DialogTitle
        component="div"
        sx={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
        }}
      >
        Choose a Preset Blueprint
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Save time by creating your new environment with one of our Blueprints.
        </DialogContentText>
        <TextField
          variant="standard"
          error={!newEnvironment ? true : false}
          margin="normal"
          id="architectureName"
          label="New Environment Name"
          value={newEnvironment}
          fullWidth
          onChange={(e) => setNewEnvironment(e.target.value)}
          inputProps={{ pattern: "[a-zA-Z0-9]{2,15}" }}
          helperText="must match regex [a-zA-Z0-9]{2,15}"
        />
        <DataGrid
          rows={blueprints}
          columns={columns}
          getRowId={(row) => `${row.source}/${row.id}`}
          onRowClick={({ row }) => setSelectedRow(row)}
          autoHeight
          //autoPageSize
          width="100%"
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
          color="primary"
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          onClick={() => addEnvironment()}
          color="primary"
          variant="contained"
          startIcon={
            <MaterialSymbol
              icon="check"
              size={24}
              grade={0}
              fill={0}
              weight={400}
            />
          }
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
